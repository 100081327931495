.faq-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }
  
  .faq-enter-active {
    max-height: 200px; /* Adjust as needed */
    opacity: 1;
  }
  
  .faq-exit {
    max-height: 200px; /* Adjust as needed */
    opacity: 1;
  }
  
  .faq-exit-active {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }
  